// src/Privacy.js
import React from "react";
import { Link } from "react-router-dom";

function FAQ() {
  return (
    <div className="bg-black text-white p-5 overflow-auto w-full min-h-screen ">
      <h1 className="text-3xl font-bold mb-6">FAQ</h1>
      <h2 className="text-lg font-bold">What is snacc?</h2>
      <p className="mb-4">Snacc is an app to compliment your friends.</p>
      <h2 className="text-lg font-bold">How much does it cost?</h2>
      <p className="mb-4">
        With snacc, it's always free to compliment your friends but snacc will
        offer upgrades in the near future to make things interesting.
      </p>
      <h2 className="text-lg font-bold">Why did you create snacc?</h2>
      <p className="mb-4">
        I created snacc after my father passed away. I wanted to make something
        that helped people feel better about themselves. The entire process with
        my dad was very lonely and I hope that snacc shows you there are people
        who love you and admire you.
      </p>
      <h2 className="text-lg font-bold">When was snacc launched?</h2>
      <p className="mb-4">
        Snacc was launched in March 2024 in Cedar Park Texas.
      </p>
      <h2 className="text-lg font-bold">Will it be available on Android?</h2>
      <p className="mb-4">I don't have a release date at this time.</p>
      <h2 className="text-lg font-bold">Who built snacc?</h2>
      <p className="mb-4">
        Snacc was built by a single developer, me, in my free time throughout
        college.
      </p>
    </div>
  );
}

export default FAQ;
