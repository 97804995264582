// src/Privacy.js
import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="bg-black text-white p-5 overflow-auto w-full min-h-screen ">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">
        snacc is a mobile app to compliment your friends. Users answer polls about
        their friends. When you select a friend in a poll, they will receive a
        notification that a friend has voted on them. Because snacc is designed
        for younger people, we care deeply about safety and user privacy. We try
        to limit the data we collect to what is necessary to use the app and
        connect with friends.
        <br />
        <br />
        Below, we outline what data we collect, how we use and share it, and
        your rights.
        <h1 className="text-2xl font-bold">Data Collection And Use</h1>
        <h2 className="text-lg font-bold">Account Data</h2>
      </p>
      <p className="mb-4">
        When you sign up for an account on snacc, you are asked to enter your
        phone number, name, school, grade level, gender, username and profile
        photo. This data is used to identify you to other snacc users and makes it
        easy for your friends to find your account on the service. If you
        provide your school, you will be listed in the classmate section of the
        app and your classmates will be able to request to be friends with you.
      </p>
      <h2 className="text-lg font-bold">Contacts Data</h2>
      <p className="mb-4">
        When signing up for an account on snacc, you have the option to give snacc
        access to your address book to find friends on snacc. We will use your
        Contacts in order to identify other Users that you may know or that your
        Contacts may know and to provide you and other Users with suggestions
        with whom to connect, such as friends-of-friends. We may also present
        your Contacts as options in polls if you do not have at least 12 friends
        on the app. Choosing Contacts in polls does not send them an invitation
        to join snacc. We will never send text messages to your Contacts without
        your explicit permission.
        <br />
        <br />
        You can invite friends to join snacc by sending them a text message with a
        link to install the app. The invitation may include your school name and
        the names of your friends on snacc. We do not automatically send messages
        to your friends on your behalf. Instead, you have to send the message
        yourself. This means you're always in control of what messages get sent
        to your friends. When sending an invitation, you are also sending a
        friend request. We do this so they can immediately accept your friend
        request after signing up.
      </p>
      <h2 className="text-lg font-bold">Location Data</h2>
      <p className="mb-4">
        Before creating an account on snacc, you have the option to give snacc
        access to your approximate location data. We will use your approximate
        location to suggest nearby schools to join and to determine if snacc is
        available in your area.
        <br />
        <br />
        Your approximate location data is not stored or linked to your identity
        and we do not track your location after your account is created.
      </p>
      <h2 className="text-lg font-bold">Device and App Activity Data</h2>
      <p className="mb-4">
        When you use the app, we automatically collect certain information about
        your device (such as the type of device, operating system, IP address,
        and unique identifiers) and how you interact with the app (such as the
        actions you take and how long you use the app). We use this information
        to improve the app, create new features, and keep our systems secure.
      </p>
      <h1 className="text-2xl font-bold">How We Share Data</h1>
      <p className="mb-4">
        We do not sell, rent, license, or lease your data to third parties.
        However, we may share it with the following third parties:
      </p>
      <ul className="list-disc p-4">
        <li>
          Service Providers – such as hosting services, customer support
          services, and analytics services.
        </li>
        <li>Professional Advisors – such as lawyers and accountants.</li>
        <li>
          Business Transaction Recipients – including counterparties and others
          assisting with a merger, acquisition, financing, reorganization,
          bankruptcy, receivership, dissolution, asset sale, or similar
          transaction involving our business.
        </li>
        <li>
          Legal and Safety – government authorities or regulators or other third
          parties where required by law or legal process or to protect snacc,
          users’, or other third parties’ rights and safety.
        </li>
      </ul>
      <h1 className="text-2xl font-bold">How You Share Data</h1>
      <p className="mb-4">
        You can use snacc to answer polls about your friends. When answering a
        poll, we send your response to your friend via the App and it includes
        your grade, gender, and the other poll options, which could include
        friends or Contacts. Your friend may reveal your name if they subscribe
        to additional services.
      </p>
      <h1 className="text-2xl font-bold">Data Retention</h1>
      <p className="mb-4">
        We keep your data until we don’t need it for the purposes described in
        this policy and we have no legal obligation to keep it.
      </p>
      <h1 className="text-2xl font-bold">Children</h1>
      <p className="mb-4">
        snacc is not intended for children under 13 and we do not believe we have
        collected any data from children under 13. If we learn that we have
        collected data from children without proper consent, we will delete that
        data.
      </p>
      <h1 className="text-2xl font-bold">Changes to this Policy</h1>
      <p className="mb-4">
        We may need to update this policy to account for changes in our business
        and applicable laws. If we change this policy, we will post the new
        version here. If the changes are significant we will notify you through
        the app.
      </p>
      <h1 className="text-2xl font-bold">Contact Us</h1>{" "}
      <p className="mb-4">
        Please email us at
        <a href="mailto:moves.platforms@gmail.com">
          moves.platforms@gmail.com
        </a>{" "}
        with any questions.
      </p>
    </div>
  );
}

export default Privacy;
