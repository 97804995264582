import amplitude from 'amplitude-js';

// Initialize Amplitude with your project API key
amplitude.getInstance().init('b63e34076e1ab71088ede95b5ec058d1');

// Function to track page views
export function trackPageView() {
  amplitude.getInstance().logEvent('Landing Page Viewed', {
    'Page URL': window.location.href,
    'Referrer': document.referrer,
    'Device Type': getDeviceType(),
    'Browser': getBrowserName()
  });
}

// Function to track download button clicks
export function trackDownloadClick() {
  amplitude.getInstance().logEvent('Download Button Clicked', {
    'Device Type': getDeviceType(),
    'Source': getQueryParam('source'),
    'Campaign': getQueryParam('campaign')
  });
}

// Helper function to get the device type
function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'Desktop';
}

// Helper function to get the browser name
function getBrowserName() {
  const userAgent = navigator.userAgent;
  if (userAgent.includes('Chrome')) {
    return 'Chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('Safari')) {
    return 'Safari';
  } else if (userAgent.includes('Edge')) {
    return 'Edge';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer';
  }
  return 'Unknown';
}

// Helper function to get the value of a query parameter
function getQueryParam(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || 'N/A';
}