// src/Terms.js
import React from "react";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div className="bg-black text-white p-5 overflow-auto w-full min-h-screen ">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <h2 className="text-lg font-bold">Thank you for using snacc</h2>
      <p className="mb-4">
        Welcome to snacc. snacc is an app to compliment your friends. We hope
        that snacc shows you that there are people who love and admire you.
      </p>
      <p className="mb-4">
        Our Terms of Service ('Terms') govern access and use of our app,
        website, and other tools (collectively 'snacc' or the 'Service' or
        'Services'). Each time you access or use snacc, you agree to be bound by
        these Terms, our{" "}
        <Link to="/privacy" className="text-blue-500 underline">
          Privacy Policy
        </Link>
        , and any additional terms that apply to you. If you do not agree to be
        bound by these Terms, please stop using snacc.
      </p>

      <h1 className="text-2xl font-bold">Your license to use snacc</h1>
      <p className="mb-4">
        We grant you a limited, non-exclusive, non-transferable, and revocable
        license to use our Service subject to these Terms, our{" "}
        <Link to="/privacy" className="text-blue-500 underline">
          Privacy Policy
        </Link>
        , and other policies, and applicable law.
      </p>

      <h2 className="text-lg font-bold">Download and Software Updates</h2>
      <p className="mb-4">
        When you create your snacc account, you agree that the information you
        submit is correct and complete. We may refuse you access to the Service
        if we learn that the information you provided is inaccurate.
        <br />
        <br />
        Using snacc requires that we initially download software to your
        computer, phone, tablet, or other device. You agree that we may
        automatically update that software, and that these Terms and our
        policies will apply to any updates.
      </p>

      <h2 className="text-lg font-bold">Age and legal capacity</h2>

      <p className="mb-4">
        You represent that you are at least 13 years of age and possess the
        legal capacity to enter into the agreement set forth in these Terms. No
        one under the age of 13 is allowed to use or access snacc. Nor may you
        use snacc if you lack the legal capacity to enter into contracts or it
        would be prohibited under U.S. law.
        <br />
        <br />
        If you are a resident of the European Economic Area ('EEA'), you may
        only use snacc if you are over the age at which you can provide consent
        to data processing under the laws of your country or if verifiable
        parental consent for your use of snacc has been provided to us.
      </p>

      <h1 className="text-2xl font-bold">You own what you post</h1>
      <p className="mb-4">
        We refer to the photos, comments, links, and other content you post as
        "User Content." You retain all rights in, and are solely responsible
        for, the User Content you post to snacc.
        <br />
        <br />
        While your content belongs to you, several important limitations apply:
        <br />
        <br />
        First, User Content does not include your snacc username. Should you or
        snacc terminate your account, you do not retain any rights in the
        username you used. A new snacc user may use the user name in the future.
        <br />
        <br />
        Second, you grant snacc and our users a non-exclusive, royalty-free,
        transferable, sublicensable, worldwide license to use, store, display,
        reproduce, save, modify, create derivative works, perform, and
        distribute your User Content for the purposes of using, accessing,
        operating, developing and providing snacc. Nothing in these Terms
        restricts other legal rights snacc may have to User Content, for example
        under other licenses.
        <br />
        <br />
        Third, we retain the right to remove or modify User Content, or change
        the way it’s used in snacc, for any reason. This includes User Content
        that in our judgment violates these Terms or any other policies.
        <br />
        <br />
        Fourth, content you share with others on snacc may continue to exist on
        snacc even if you leave snacc. We may backup, archive and retain your
        User Content even if you terminate or deactivate your account, or delete
        or remove specific User Content. snacc and its users may retain and
        continue to use, store, display, reproduce, re-pin, modify, create
        derivative works, perform, and distribute any of your User Content that
        other users have stored or shared on snacc.
      </p>

      <h1 className="text-2xl font-bold">
        If you post or visit a link posted on snacc
      </h1>

      <p className="mb-4">
        We may allow you to post links to third party websites, information,
        materials, products, or services. Please do not post links in violation
        of our terms. snacc does not control nor endorse or assume any
        responsibility for these links. If you access any third party website,
        service, or content from snacc, you do so at your own risk. You accept
        and agree that snacc has no liability arising from your use of or access
        to any third party website, service, or content.
      </p>

      <h1 className="text-2xl font-bold">If you provide us feedback</h1>

      <p className="mb-4">
        We appreciate user input on ways to make snacc better. If you make any
        comments or suggestions to snacc, you agree that we may act upon your
        feedback without any restriction or compensation to you.
        <br />
        <br />
        snacc doesn’t waive any rights to use similar or related feedback
        provided snacc before or after your feedback, or developed by snacc’s
        employees, or obtained from sources other than you.
      </p>

      <h1 className="text-2xl font-bold">Keeping your account secure</h1>

      <p className="mb-4">
        While we can not guarantee your account from hacking by an unauthorized
        party, we employ security measures to keep your account and content
        secure. You can help us by keeping your SMS authentication code to
        yourself. Let us know right away of any unauthorized use or compromise
        to your account.
      </p>

      <h1 className="text-2xl font-bold">Conditions of Access and Use</h1>

      <h2 className="text-lg font-bold">User Conduct</h2>
      <p className="mb-4">
        You are solely responsible for all code, video, images, information,
        data, text, software, music, sound, photographs, graphics, messages, and
        other materials (“content”) that you make available to Company,
        including by uploading, posting, publishing, or displaying (hereinafter,
        “upload(ing)”) via the Service or by emailing or otherwise making
        available to other users of the Service (collectively, “User Content”).
        The following are examples of the kinds of content and/or uses that are
        illegal or prohibited by Company. Company reserves the right to
        investigate and take appropriate legal action against anyone who, in
        Company’s sole discretion, violates this provision, including removing
        the offending content from the Service, suspending or terminating the
        account of such violators, and reporting the violator to law enforcement
        authorities. You agree to not use the Service to:
      </p>

      <ol className="list-decimal pl-6 pr-6">
        <li>
          email or otherwise upload any content that (i) infringes any
          intellectual property or other proprietary rights of any party; (ii)
          you do not have a right to upload under any law or under contractual
          or fiduciary relationships; (iii) contains software viruses or any
          other computer code, files or programs designed to interrupt, destroy,
          or limit the functionality of any computer software or hardware or
          telecommunications equipment; (iv) poses or creates a privacy or
          security risk to any person; (v) constitutes unsolicited or
          unauthorized advertising, promotional materials, commercial activities
          and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,”
          “contests,” “sweepstakes,” or any other form of solicitation; (vi) is
          unlawful, harmful, threatening, abusive, harassing, tortious,
          excessively violent, defamatory, vulgar, obscene, pornographic,
          libelous, invasive of another’s privacy, hateful, discriminatory, or
          otherwise objectionable; or (vii) in the sole judgment of Company, is
          objectionable or which restricts or inhibits any other person from
          using or enjoying the Service, or which may expose Company or its
          users to any harm or liability of any type;
        </li>
        <li>
          interfere with or disrupt the Service or servers or networks connected
          to the Service, or disobey any requirements, procedures, policies, or
          regulations of networks connected to the Service;
        </li>
        <li>
          violate any applicable local, state, national, or international law,
          or any regulations having the force of law;
        </li>
        <li>
          impersonate any person or entity, or falsely state or otherwise
          misrepresent your affiliation with a person or entity;
        </li>
        <li>solicit personal information from anyone under the age of 18;</li>
        <li>
          harvest or collect email addresses or other contact information of
          other users from the Service by electronic or other means for the
          purposes of sending unsolicited emails or other unsolicited
          communications;
        </li>
        <li>
          advertise or offer to sell or buy any goods or services for any
          business purpose that is not specifically authorized;
        </li>
        <li>
          further or promote any criminal activity or enterprise or provide
          instructional information about illegal activities
        </li>
        <li>
          obtain or attempt to access or otherwise obtain any content or
          information through any means not intentionally made available or
          provided for through the Service;
        </li>
        <li>
          circumvent, remove, alter, deactivate, degrade, or thwart any of the
          content protections in or geographic restrictions on any content
          (including Service Content (as defined below)) available on or through
          the Service, including through the use of virtual private networks; or
        </li>
        <li>
          engage in or use any data mining, robots, scraping, or similar data
          gathering or extraction methods. If you are blocked by Company from
          accessing the Service (including by blocking your IP address), you
          agree not to implement any measures to circumvent such blocking (e.g.,
          by masking your IP address or using a proxy IP address or virtual
          private network).
        </li>
      </ol>

      <h2 className="text-lg font-bold">Competitors</h2>
      <p className="mb-4">
        No employee, independent contractor, agent, or affiliate of any
        competing social media company is permitted to view, access, or use any
        portion of the Service without express written permission from Company.
        By viewing, using, or accessing the Service, you represent and warrant
        that you are not a competitor of Company or any of its affiliates, or
        acting on behalf of a competitor of Company in using or accessing the
        Service.
      </p>

      <h2 className="text-lg font-bold">Fees</h2>
      <p className="mb-4">
        To the extent the Service or any portion thereof is made available for
        any fee, you may be required to select a payment plan and provide
        information regarding your credit card or other payment instrument. You
        represent and warrant to Company that such information is true and that
        you are authorized to use the payment instrument. You will promptly
        update your account information with Company or the Payment Processor
        (as defined below), as applicable, of any changes (for example, a change
        in your billing address or credit card expiration date) that may occur.
        You agree to pay Company the amount that is specified in the payment
        plan in accordance with the terms of such plan and these Terms of
        Service. If your payment plan includes an ongoing subscription that is
        automatically renewed periodically, you hereby authorize Company
        (through the Payment Processor) to bill your payment instrument in
        advance on such periodic basis in accordance with the terms of the
        applicable payment plan until you terminate your account, and you
        further agree to pay any charges so incurred. If you dispute any charges
        you must let Company know within sixty (60) days after the date that
        Company charges you. We reserve the right to change Company’s prices. If
        Company does change prices, Company will provide notice of the change
        through the Service user interface, a pop-up notice, email, or through
        other reasonable means, at Company’s option, at least thirty (30) days
        before the change is to take effect. Your continued use of the Service
        after the price change becomes effective constitutes your agreement to
        pay the changed amount. You will be responsible for all taxes associated
        with the Service, other than taxes based on Company’s net income.
      </p>

      <h2 className="text-lg font-bold">Commercial Use</h2>
      <p className="mb-4">
        Unless otherwise expressly authorized herein or in the Service, you
        agree not to display, distribute, license, perform, publish, reproduce,
        duplicate, copy, create derivative works from, modify, sell, resell,
        exploit, transfer, or upload for any commercial purposes, any portion of
        the Service, use of the Service, or access to the Service. The Service
        is for your personal use.
      </p>

      <h1 className="text-2xl font-bold">Subscriptions</h1>
      <h2 className="text-lg font-bold">Automatic Renewal and Cancellation</h2>

      <p className="mb-4">
        snacc offers Users a weekly subscription for an enhanced user
        experience. If you sign up for a subscription, your subscription will
        automatically renew until you cancel, and you will automatically be
        charged the associated fee (plus any applicable taxes) until you cancel.
        To see your next renewal date and current fee, visit your mobile
        device’s account settings, or the Subscriptions tab that is available
        through the Apple App Store.
        <br />
        <br />
        You must cancel your subscription at least 24 hours before the end of
        your current billing period to avoid being charged for the next billing
        period. After cancellation, you will have access to your subscription
        through the last day of your current billing period, at which point your
        subscription (and access to your subscription features) will be
        discontinued.
        <br />
        <br />
        You may cancel your subscription any time through your Apple App Store.
        To access the subscription features after discontinuing your paid
        subscription, you may need to purchase a new subscription, subject to
        then-current fees, terms, and conditions.
      </p>

      <h2 className="text-lg font-bold">Paying For Your Subscription</h2>

      <p className="mb-4">
        Payments for your subscription are processed via Apple (“App Provider”)
        and you will need to provide your App Provider with your payment
        information. You are responsible for maintaining accurate and up-to-date
        payment information. If a payment is unsuccessful, we may suspend your
        access to the relevant subscription features until you provide a valid
        payment method. snacc is not responsible if your subscription fails to
        renew due to invalid payment information.
      </p>

      <h2 className="text-lg font-bold">Refunds</h2>

      <p className="mb-4">
        Refund requests are handled by your App Provider, not snacc. To request
        a refund, you should follow your App Provider’s refund procedures. snacc
        does not offer refunds for your subscription. If you cancel, we will not
        issue you a refund or credit for any partial-period access to your
        subscription. You are not entitled to a refund or credit for any part of
        a billing period if the Subscription Features are unavailable,
        defective, experiencing delays, or for any other reason.
      </p>
      <h2 className="text-lg font-bold">
        Changes to Subscriptions and Features
      </h2>
      <p className="mb-4">
        We reserve the right to change or discontinue your subscription plans,
        subscription features, terms, or pricing at any time. snacc is not
        responsible for any damage or loss caused by failures or delays of your
        subscription or the subscription features.
      </p>

     

      
    </div>
  );
}

export default Terms;
