import React, { useEffect } from "react";
import logo from "./skibidi.svg"; // Your logo import
import download from "./appstore.svg"; // Your download icon import
// Import your social icons similarly if they are available as SVGs
import ig from "./instagram-white-icon.svg";
import { trackPageView, trackDownloadClick } from "./amplitude"; // Import the tracking functions


export default function Home() {
  useEffect(() => {
    trackPageView(); // Track page view when the component mounts
  }, []);

  const handleDownloadClick = () => {
    trackDownloadClick(); // Track download button click
    // Redirect to the app store or perform any other necessary action
  };

  return (
    <div className="flex flex-col justify-between w-full min-h-screen bg-brand text-white font-sans">
      <div className="flex flex-col items-center justify-center mt-44 mb-16 space-y-6 ">
        <img src={logo} className="w-64 pl-2.5" alt="logo" />
        {/* Logo is larger and has margin-bottom */}
        <p className=" mb-6 text-3xl">See who likes you</p>
        {/* Larger text and margin-bottom */}
        <a href="https://apps.apple.com/us/app/moves-social/id6447489791" onClick={handleDownloadClick}>
          {" "}
          <img
            src={download}
            className="mb-4 w-48 shadow-md hover:shadow-lg hover:cursor-pointer"
            alt="download"
          />
        </a>

        {/* Download icon has margin-bottom */}
        <p className="opacity-75 text-lg text-center">
          Available at <b>Cedar Park</b>
        </p>
      </div>
      <footer className="flex flex-col items-center pb-8 pt-8 space-y-4 bg-black bg-opacity-50">
        <div className="flex flex-row items-center space-x-8 mb-4 text-base ">
          <a
            href="mailto:moves.platforms@gmail.com"
            className="hover:underline"
          >
            Email us
          </a>
          <a href="/faq" className="hover:underline">
            FAQ
          </a>
          <a href="/terms" className="hover:underline">
            Terms
          </a>
          <a href="/privacy" className="hover:underline">
            Privacy
          </a>
        </div>
        <div className="flex justify-center space-x-6  text-sm opacity-50">
          {/* Replace `SocialIcon` with the actual imports of your icons */}
          <img src={ig} alt="Instagram" className="w-6 h-6" />
        </div>
        <p className="text-sm opacity-50">© Skibidi 2024.</p>
      </footer>
    </div>
  );
}
